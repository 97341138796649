import { useMemo, useCallback, useReducer, useState, useEffect } from 'react';


export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const INVOKE_METHODS = {
    GET_NOTIFICATIONS: 'GetNotifications',
    MARK_NOTIFICATION_AS_READ: 'MarkNotificationAsRead'
}
export const EVENTS = {
    RECEIVE_NOTIFICATION: 'ReceiveNotification'
}

const defaultState = {
    notifications: []
}

const notificationsReducer = (state, { type, payload }) => {
    switch (type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload
            };
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [payload, ...state.notifications]
            };
        case READ_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.id !== payload.notificationId
                )
            }
        default:
            return state;
    }
};

export const useNotifications = ({
    connection, onUnauthorized,
}) => {
    const [notificationState, dispatchNotification] = useReducer(
        notificationsReducer,
        defaultState
    );
    const [error, setError] = useState(null);

    const readNotification = useCallback(async (notificationId) => {
        try {
            await connection.invoke(INVOKE_METHODS.MARK_NOTIFICATION_AS_READ, notificationId);
            dispatchNotification({ type: READ_NOTIFICATION, payload: { notificationId } });
        } catch (error) {
            onUnauthorized(error);
            setError(error);
        }
    }, [dispatchNotification, setError, connection, onUnauthorized]);

    const getNotReadNotifications = useCallback(async () => {
        try {
            const { notifications = [] } = await connection.invoke(INVOKE_METHODS.GET_NOTIFICATIONS, {
                isRead: false,
            });

            setError(null);
            if (notifications) {
                dispatchNotification({ type: SET_NOTIFICATIONS, payload: notifications });
            }
        } catch (error) {
            onUnauthorized(error);
            setError(error);
        }
    }, [dispatchNotification, setError, connection, onUnauthorized]);

    const receiveNotification = useCallback(
        (notification) => {
            setError(null);
            dispatchNotification({ type: ADD_NOTIFICATION, payload: notification });
        },
        [dispatchNotification, setError],
    );

    useEffect( () => {
        async function init () {
            if (connection) {
                await getNotReadNotifications();

                connection.on(EVENTS.RECEIVE_NOTIFICATION, receiveNotification);
                connection.onreconnected(async() => {
                    await getNotReadNotifications();
                })
            }
        }

        init();
    }, [connection]);

    return useMemo(() => ({
        notifications: notificationState.notifications,
        readNotification,
        error
    }), [
        notificationState,
        readNotification,
        error
    ]);
};
