import { DEFAULT_LOCALE } from './defaults';
import {
    selectLocale, getInitialLocales, localesEmpty, validateNewLocale,
} from './utils';

import LocaleBase from './LocaleBse';

export class LocaleService extends LocaleBase {
    locale = DEFAULT_LOCALE;

    name = 'default';

    locales;

    constructor(localesInfo = {}) {
        super();

        if (localesEmpty(localesInfo)) return;

        const locales = getInitialLocales(localesInfo);

        if (localesEmpty(locales)) return;

        this.locales = locales;

        this.setLocale();
    }

    add(newLocaleName = '', newLocale = {}) {
        if (!validateNewLocale(newLocaleName, newLocale)) return;

        this.locales[newLocaleName] = newLocale;

        this.setLocale();
    }

    remove(locale) {
        delete this.locales[locale];

        this.setLocale();

        this.notify();
    }

    setLocale() {
        const name = selectLocale(this.locales);

        const { locale } = this.locales[name];

        if (!locale) return;

        this.locale = locale;
        this.name = name;
        this.notify();
    }

    notify() {
        this.notifySubscribers(this.localization);
    }

    get localization() {
        return { locale: this.locale, name: this.name };
    }
}
