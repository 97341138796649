import React, { createContext, useMemo } from 'react';
import { useConnection } from '../hooks/useConnection';
import { useNotifications } from '../hooks/useNotifications';

export const NotificationContext = createContext({});

NotificationContext.displayName = 'NotificationContext';

/**
 *
 * @param children
 * @param {Object} config
 * @param {String} config.url
 * @param {String} config.accessToken
 * @param {Number} config.debugLevel
 * @param {Boolean} config.startWhenReady
 * @param {number} config.automaticReconnectTimeout
 * @returns {JSX.Element}
 * @constructor
 */
export const NotificationProvider = ({
    children,
    config = {
        debugLevel: 0,
        automaticReconnectTimeout: 5000,
    },
}) => {
    const {
        connection,
        connectionState,
        setup: start,
        stop,
        error: connectionError,
        onUnauthorized,
    } = useConnection(config);

    const {
        notifications,
        readNotification,
        error: notificationError,
    } = useNotifications({ connection, onUnauthorized });

    const value = useMemo(() => ({
        notifications,
        readNotification,
        connectionState,
        start,
        stop,
        error: connectionError || notificationError,
    }), [
        notifications,
        readNotification,
        connectionState,
        start,
        stop,
        connectionError,
        notificationError,
    ]);

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
