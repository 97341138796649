export default class LocaleBase {
    listeners = [];

    subscribe(cb) {
        if (!cb) return undefined;

        const index = this.listeners.push(cb) - 1;

        return {
            unsubscribe: () => {
                if (this.listeners[index]) this.listeners.splice(index, 1);
            },
        };
    }

    notifySubscribers(data) {
        if (this.listeners.length === 0) return;

        for (let i = 0; i < this.listeners.length; i++) {
            this.listeners[i](data);
        }
    }
}
